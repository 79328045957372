@use "sass:map"
@import "../mixins/layout.sass"

$margins: (right: 2rem, left: 2rem)

@function get-margin($size, $max-width)
  @return calc(#{$size} + (100% - #{$max-width}) / 2)

=use-margin($margin, $max-width)
  $right: map.get($margin, right)
  $left: map.get($margin, left)

  margin-right: max($left, get-margin($right, $max-width))
  margin-left: max($right, get-margin($left, $max-width))
  max-width: min(100%, #{calc($max-width - $left - $right)})

.container
  max-width: get-breakpoint(xs)
  +use-margin($margins, get-breakpoint(xs))


.container-fluid
  max-width: 100%
  +use-margin($margins, 100%)


.container-xs
    max-width: get-breakpoint(xs)
    +use-margin($margins, get-breakpoint(xs))
  

.container-sm
    max-width: get-breakpoint(sm)
    +use-margin($margins, get-breakpoint(sm))
  

.container-md
    max-width: get-breakpoint(md)
    +use-margin($margins, get-breakpoint(md))
  

.container-lg
    max-width: get-breakpoint(lg)
    +use-margin($margins, get-breakpoint(lg))


.container-xl
    max-width: get-breakpoint(xl)
    +use-margin($margins, get-breakpoint(xl))


.container-xxl
    max-width: get-breakpoint(xxl)
    +use-margin($margins, get-breakpoint(xxl))


.container-vertical
  flex-direction: column
