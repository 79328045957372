@use "sass:map"
@use "../globals/breakpoints"
@use "../globals/colorscheme"

$bg-color: colorscheme.$background-secondary
$primary-font: 'Iceland'
$accent-color: colorscheme.$accent


.login-box
  padding: 2rem
  display: flex
  flex-direction: column
  gap: 2rem
  background: $bg-color
  border-color: $accent-color
  border-style: solid
  border-radius: 5px
  border-width: 1px
  box-shadow: 0 0 1rem 0 rgb(0 0 0 / 0.2)


.login-title
  display: flex
  flex-direction: column
  font-family: $primary-font
  font-size: 1.5rem
  font-weight: 400
  color: white


.formRow
  display: flex
  flex-direction: column
  font-family: $primary-font
  font-size: 1.33rem
  font-weight: 400
  color: white

  
.login-button
  padding: 0.7rem 2rem
  font-family: $primary-font
  font-size: 1.35rem
  font-weight: 400
  background-color: $accent-color
  color: white
  border-style: none
  border-radius: 5px
