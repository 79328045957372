@use "sass:math"
@use "../globals/breakpoints"
@use "../globals/colorscheme"
@use "../globals/fonts"
@import "../mixins/layout.sass"
  
$title-size: 5rem
$primary-text-color: colorscheme.$text-primary
$accent-color: colorscheme.$accent
$corner-radius: 0.25rem
$title-bg: colorscheme.$accent
$title-fg: white
$title-font-size: 1.5rem
$border-color: colorscheme.$accent
$content-primary-bg: colorscheme.$background-primary
$content-weight: 600

.home-content-field
  display: flex
  flex-direction: column


.home
  display: flex
  flex-direction: column
  gap: 5rem

  +lg
    flex-direction: row
    align-items: center


.home-section
  border-bottom: 1px solid colorscheme.$divider


.home-panel
  display: flex
  flex-direction: column


.home-panel-primary
  max-width: 50rem
  margin: 0 auto

  +lg
    width: 61.803%
    max-width: unset
    margin: unset

  .button__group  
    flex-direction: column

    +xs
      flex-direction: row

  .button
    text-align: center


.home-panel-secondary
  width: 100%
  max-width: 30rem
  margin: 0 auto

  +lg
    width: 38.197%
    max-width: unset


.home-title
  display: block
  width: 100%
  font-family: fonts.$logo
  font-size: 5rem
  align-self: center
  text-align: center
  color: white
  text-transform: uppercase

  +sm
    font-size: 6rem

  +lg
    text-align: left

  +xl
    font-size: 7rem


.home-title-subtext
  font-family: fonts.$body-text
  font-size: 1.5rem
  text-align: center
  color: white
  margin-bottom: 3rem
  font-style: italic
  hyphens: manual
  margin-top: 0.5rem

  +sm
    font-size: 1.75rem


  +lg
    font-size: 2rem
    text-align: left
    margin-top: 0rem


.home-description
  margin-bottom: 2rem
  font-family: fonts.$body-text
  font-size: 1.5rem
  line-height: 1.75
  text-align: center
  color: white
  text-shadow: 0 0 1rem #000

  +lg
    text-align: left


.home-title-section
  display: flex
  flex-direction: column
  align-items: center
  margin-bottom: 2rem
  

.home-secondary-title
  margin-bottom: 3rem

  font-family: Iceland
  font-size: 3rem
  text-align: center
  color: white


.home-info-container
  max-width: 30rem
  margin: 0 auto

  display: flex
  flex-direction: column
  align-items: stretch
  gap: 3rem

  +md
    max-width: unset
    margin: unset

    flex-direction: row
    justify-content: space-between
    gap: 2rem

  +lg
    gap: 4rem


.home-info-box
  +md
    max-width: 25rem
    flex: 1


.home-info-box-text
  color: #fff
  font-family: fonts.$body-text
  text-align: center

  &:not(:last-child)
    $info-box-gap: math.div(1.5rem, 2)

    margin-bottom: $info-box-gap
    padding-bottom: $info-box-gap
    border-bottom: 1px solid colorscheme.$background-primary


.config-descriptor
  padding: 1.5rem

  &:not(:last-child)
    border-bottom: 1px solid colorscheme.$background-primary


.config-view-badge.config-descriptor-header
  max-width: min-content
  margin-bottom: 0.5rem
  padding: 0.15rem 0.3rem
  overflow: hidden

  white-space: nowrap
  text-overflow: ellipsis
  background-color: colorscheme.$accent
  color: colorscheme.$background-primary


.config-descriptor-content
  display: flex 
  flex-wrap: wrap
  justify-content: space-between
  align-items: center
  row-gap: 0.5rem
  column-gap: 1rem
  font-family: fonts.$body-text
  color: #fff


.config-descriptor-info
  display: flex
  flex-direction: column-reverse
  gap: 0.5rem


.config-descriptor-name
  font-weight: 600

  &, &:visited
    color: colorscheme.$accent
    transition-duration: 200ms

  &:hover
    color: darken(colorscheme.$accent, 10%)


.config-descriptor-owner
  font-weight: normal

  .main-menu-profile-link
    gap: 0.75rem

  .profile-picture
    margin: 0

  a, a:visited
    color: #fff
    transition-duration: 200ms

  a:hover
    color: colorscheme.$text-primary


.config-descriptor-created-ago
  display: flex
  align-items: center
  gap: 0.5rem

  font-family: fonts.$body-text
  font-size: 0.9rem
  font-style: italic
  color: colorscheme.$text-primary

  svg
    width: 0.9rem
    fill: colorscheme.$text-primary


.profile-picture-extra-small
  width: 2rem !important
  height: 2rem !important
  border: none !important


.recent-configs-loading,
.configs-not-found
  color: white
  font-family: fonts.$body-text


.home-search-bar
  width: 100%
  display: flex

  overflow: hidden
  border-radius: 0.25rem

  input
    padding: 0.75rem 1rem

    appearance: none
    outline: none


.home-search-bar-field
  flex-grow: 10

  border: 2px solid colorscheme.$accent
  border-right: none
  background-color: darken(colorscheme.$background-primary, 5%)
  font-size: 1rem
  font-weight: 500
  color: #fff

  &::placeholder
    color: colorscheme.$text-primary


.home-search-bar-button
  flex-grow: 1

  border: none
  background-color: colorscheme.$accent
  font-family: Iceland
  font-size: 1.15rem
  color: #fff


.home-search-label
  display: none
