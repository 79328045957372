@use "../globals/breakpoints"
@use "../globals/colorscheme"
@use "../globals/fonts"
@import "../mixins/layout.sass"

$body-text-font: fonts.$body-text

.profile-view
  font-family: $body-text-font
  color: #fff


.profile-view-header
  display: flex
  flex-direction: column
  align-items: center

  +sm
    flex-direction: row
    gap: 2rem


.profile-view-header-section
  border-bottom: 1px solid colorscheme.$divider


.profile-view-configs-title
  font-family: fonts.$title
  font-size: 1.5rem
  font-weight: 500
  text-align: center


.profile-basic-info
  width: 100%
  margin-top: 1rem

  +xs
    width: unset

  +sm
    margin-top: 0
    display: flex
    flex-direction: column
    gap: 1rem


.config-list
  margin-top: 2rem

  display: grid
  gap: 2rem

  list-style-type: none

  +md
    grid-template-columns: 1fr 1fr

  +lg
    grid-template-columns: repeat(3, 1fr)


.config-list-loading,
.config-list-not-found
  margin: 2rem 0

  display: flex
  flex-direction: column-reverse
  align-items: center

  text-align: center
  font-size: 1.15rem


.config-list-not-found
  font-style: italic


.user-stats
  margin-top: 2rem
  padding: 1rem
  display: flex
  gap: 2rem
  flex-direction: column
  background-color: darken(colorscheme.$background-primary, 5%)
  border-radius: 0.5rem
  border: 1px solid colorscheme.$accent

  +xs
    display: inline-flex
    flex-direction: row
    flex-wrap: wrap

  +sm
    margin-top: 0
    padding: 1rem
    justify-content: start

.user-stat-item
  display: flex
  flex-wrap: wrap
  align-items: center
  gap: 0.75rem

  font-size: 1rem
  font-weight: 500

  +xs
    flex-wrap: nowrap

  svg
    fill: #fff
    width: 1rem

  p
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis


.username
  font-family: fonts.$body-text
  font-size: 2rem
  text-align: center

  +sm
    text-align: left


.profile-view-content-top
  display: flex
  align-items: center
  justify-content: space-between


.profile-view-create-config
  display: flex
  align-items: center
  gap: 0.5rem

  svg
    fill: #fff
    width: 0.875rem

$primary-text-color: colorscheme.$text-primary
$accent-color: colorscheme.$accent
$divider: colorscheme.$divider

.profile-picture
  width: 10rem
  height: 10rem
  aspect-ratio: 1

  position: relative
  display: inline-block

  border: 3px solid $accent-color
  border-radius: 50%


  &.profile-picture-small
    width: 3rem
    height: 3rem
    margin: 0
    border-width: 2px

  img
    border-radius: 50%
    width: 100%
    height: 100%
    object-fit: cover
    position: relative

    /**
     * Pseudo classes don't apply on img tags unless the provided
     * source does not point to a valid image. So this will show
     * the default placeholder avatar if a source is provided but
     * the image is broken.
     */
    &:before
      content: " "
      display: block
      position: absolute
      height: 100%
      width: 100%
      background-image: url(../../assets/default-avatar.svg)
      background-size: contain

  .profile-picture-actions
    display: none
    position: absolute
    right: -10%
    bottom: 0
    gap: 0.5rem
    z-index: 10000

    &.active
      display: flex

  &:hover
    .profile-picture-actions
      display: flex

  .profile-picture-action-button
    width: 2.5rem
    height: 2.5rem
    display: flex
    justify-content: center
    align-items: center
    border-radius: 50%
    border: none
    background-color: darken(colorscheme.$background-primary, 5%)
    fill: white
    transition-duration: 250ms

    &:hover
      background-color: darken(colorscheme.$background-primary, 7.5%)
      box-shadow: 0 0 0.5rem 0 #00000066

    svg
      width: 1.1rem
      height: 1.1rem


.avatar-loading-icon
  position: absolute
  bottom: calc(50% - 40px)
  left: calc(50% - 40px)
  z-index: 1000

.configs-loading-icon
  position: relative

.avatar-loading-icon,
.configs-loading-icon
  display: inline-block
  width: 80px
  height: 80px

  div
    position: absolute
    top: 33px
    width: 13px
    height: 13px
    border-radius: 50%
    background: #ffffff
    box-shadow: 0 0 1rem 0 #00000044
    animation-timing-function: cubic-bezier(0, 1, 1, 0)

    &:nth-child(1)
      left: 8px
      animation: lds-ellipsis1 0.6s infinite

    &:nth-child(2)
      left: 8px
      animation: lds-ellipsis2 0.6s infinite

    &:nth-child(3)
      left: 32px
      animation: lds-ellipsis2 0.6s infinite

    &:nth-child(4)
      left: 56px
      animation: lds-ellipsis3 0.6s infinite

@keyframes lds-ellipsis1
  0%
    transform: scale(0)

  100%
    transform: scale(1)

@keyframes lds-ellipsis3
  0%
    transform: scale(1)

  100%
    transform: scale(0)

@keyframes lds-ellipsis2
  0%
    transform: translate(0, 0)

  100%
    transform: translate(24px, 0)

.profile-error
  padding: 4rem
  color: white
  text-align: center
  font-family: $body-text-font
  font-size: 2rem
