@use "../globals/fonts"
@use "../globals/colorscheme"

$title-bg: colorscheme.$accent
$title-fg: white
$title-font-size: 1.5rem
$border-color: colorscheme.$accent
$corner-radius: 0.25rem
$content-primary-bg: darken(colorscheme.$background-primary, 5%)
$content-weight: 600

.box
  overflow: hidden
  background-color: $content-primary-bg
  box-shadow: 0 0 1rem 0 hsla(0, 0, 0, 20%)
  border-radius: $corner-radius


.box-header
  padding: 1rem 2rem
  background-color: $title-bg
  font-family: fonts.$title
  color: $title-fg
  text-align: center
  font-weight: 600


.box-content
  border-top: none
  padding: 1rem


.box-item
  padding: 1rem
  color: white
  font-family: fonts.$body-text
  font-weight: $content-weight


.box-link
  color: white
  text-decoration: none !important


.box-content-no-padding
  padding: 0
