@use "../globals/breakpoints"
@use "../globals/colorscheme"
@use "../globals/fonts"
@import "../mixins/layout"
  
.privacy-policy
  h1, h2
    margin: 0

  h1
    margin-bottom: 3rem
    padding-bottom: 1rem
    border-bottom: 1px solid colorscheme.$divider

  h2
    margin-top: 2rem
    margin-bottom: 2rem

  p, li
    color: colorscheme.$text-primary
    line-height: 1.5

    &:not(:last-child)
      margin-bottom: 1rem

  p
    margin-bottom: 2rem

  ul
    margin-bottom: 1rem
    margin-left: 2rem

  li
    margin-bottom: 1rem


.faq h2
  font-size: 1.5rem
