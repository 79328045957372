@use "../globals/fonts"
@use "../globals/colorscheme"

$title-bg-color: colorscheme.$accent
$body-bg-color: darken(colorscheme.$background-primary, 5%)
$border-color: colorscheme.$divider

.overlay
  width: calc(100% - 4rem)
  max-width: 40rem

  overflow: hidden
  color: #fff
  background-color: $body-bg-color
  border-radius: 0.25rem
  box-shadow: 0 0 1rem 0 #00000044


.overlay--small
  max-width: 28rem


.overlay__background
  display: flex
  justify-content: center
  align-items: center

  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: 1e6

  background-color: hsla(0, 0, 100%, 20%)


.overlay__title
  padding: 1rem 1.5rem
  background-color: $title-bg-color

  font-family: fonts.$title
  font-weight: 600


.overlay__body
  padding: 1.5rem


.overlay__body--unpadded
  padding: 0


.overlay__text
  font-family: fonts.$body-text


.confirmation-overlay-text
  margin-bottom: 1rem
