@use "../globals/fonts"
@use "../globals/colorscheme"

$primary-text-color: colorscheme.$text-primary
$accent-color: colorscheme.$accent
$divider: colorscheme.$divider

.toast
  width: 100%
  padding: 0.5rem 2rem
  position: fixed
  top: 0
  transform: translate(-50%, -100%)
  left: 50%
  font-family: fonts.$body-text
  font-size: 0.85rem
  font-weight: 600
  color: white
  box-shadow: 0 0 1rem 0 #00000022
  z-index: 1e5
  cursor: pointer
  text-align: center
  text-transform: uppercase
  transition: transform 300ms


.toast-error
  background-color: #f93f32


.toast-ok
  background-color: #21B03C


.toast-active
  transform: translate(-50%, 0)
