@use "../globals/breakpoints"
@use "../globals/colorscheme"
@use "../globals/fonts"
@import "../mixins/layout.sass"
  
$title-size: 5rem
$primary-text-color: colorscheme.$text-primary
$accent-color: colorscheme.$accent
$bg-color: colorscheme.$background-secondary
$bg-primary: colorscheme.$background-primary
$divider-color: lighten(colorscheme.$background-primary, 5%)

$max-content-width: map-get(breakpoints.$breakpoints, xl)

$editor-bg: colorscheme.$background-primary
$editor-fg: colorscheme.$text-primary
$editor-border-color: $divider-color
$editor-gutter-bg: colorscheme.$background-primary
$editor-gutter-fg: lighten(colorscheme.$background-primary, 10%)
$editor-gutter-active-bg: colorscheme.$background-secondary
$editor-gutter-active-fg: colorscheme.$accent
$editor-line-active-bg: colorscheme.$background-secondary
$editor-cursor-fg: colorscheme.$accent
$editor-scrollbar-bg: colorscheme.$background-secondary
$editor-selection-bg: lighten(colorscheme.$background-secondary, 5%)

.config-view-delete-text
  margin-bottom: 1rem


.config-view
  font-family: fonts.$body-text
  color: #fff


.config-view-section
  border-bottom: 1px solid colorscheme.$divider


.config-view-header
  display: flex
  flex-direction: column
  gap: 1.5rem


.config-view-header-row
  display: flex
  flex-direction: column
  gap: 1rem

  +xs
    flex-direction: row
    justify-content: space-between


.config-view-header-info
  display: flex
  flex-direction: column
  gap: 0.5rem


.config-name
    font-family: fonts.$title
    font-size: 1.33rem
    font-weight: 600
    color: colorscheme.$accent


.config-owner
    font-weight: 400
    font-style: italic
    font-size: 0.9rem
    color: colorscheme.$text-primary

    a, a:visited
        color: colorscheme.$accent

    a:hover
        text-decoration: underline


.config-view-badges
  display: flex
  flex-wrap: wrap
  gap: 0.5rem
  overflow: hidden


.config-view-badge
  padding: 0.2rem 0.4rem
  font-family: fonts.$ui
  font-weight: 500
  font-size: 0.8rem
  border-radius: 0.25rem
  background-color: colorscheme.$accent
  color: colorscheme.$background-primary


.config-view-badge-red
  background-color: colorscheme.$danger


.config-view-editor-empty-container
    display: flex
    justify-content: center


.config-view-editor-empty
    display: inline-flex
    flex-direction: column
    gap: 2rem
    text-align: center


.config-view-editor-resources-container
    +md
        display: flex
        align-items: stretch
        gap: 3rem


.config-view-description
    font-size: 1rem
    line-height: 1.5
    color: colorscheme.$text-primary

    &.italic
      font-style: italic
      color: lighten(colorscheme.$background-secondary, 10%)


.config-view-description-edit
  min-height: 5rem
  max-height: 15rem
  height: 8rem
  resize: vertical


.config-view-editor-resources
    padding-bottom: 0.75rem
    overflow: hidden
    list-style-type: none
    background-color: colorscheme.$background-primary
    border: 1px solid $editor-border-color
    border-radius: 0.25rem
    box-shadow: 0 0 1rem 0 #00000044
    user-select: none

    +md
        width: 25%

.resource-header
    color: colorscheme.$text-primary
    display: flex
    align-items: center
    gap: 0.5rem
    font-size: 0.85rem
    height: 2rem
    padding: 0 1.666rem

    p
      cursor: pointer
      flex-grow: 1

    svg
        fill: colorscheme.$text-primary

    &:hover,
    &.resource-header-active
        background-color: colorscheme.$background-secondary
        color: #fff

        svg
            fill: #fff

    &:hover
        .resource-delete-button
          display: block


.resource-delete-button
  display: none


.no-open-resource-message
    margin-top: 2rem
    display: flex
    align-items: center
    justify-content: center
    width: 100%

    +md
        margin-top: 0

    p
        text-align: center
        flex-grow: 1
        font-size: 1.25rem
        font-weight: 600
        color: colorscheme.$text-primary


.config-view-editor-resources-header
    padding: 1rem 1.25rem
    margin-bottom: 0.75rem
    border-bottom: 1px solid $editor-border-color
    display: flex
    justify-content: space-between
    align-items: center


.config-view-editor-resources-title
    font-weight: 600
    font-size: 0.85rem
    text-transform: uppercase
    color: colorscheme.$text-primary


.config-view-content
    +md
        flex-grow: 1
        width: 75%


.config-view-content-controls
    margin-top: 2rem
    margin-bottom: 1rem

    display: flex
    flex-direction: column
    justify-content: space-between
    gap: 1rem

    +xs
      flex-direction: row
      align-items: center

    +md
      margin-top: 0


.config-view-filename
    font-size: 1rem


.config-view-editor-buttons,
.config-view-header-buttons
  +sm
    width: unset

  .button
    flex-grow: 1
    white-space: nowrap

  .button p
    display: none

    +sm
      display: unset


.config-preview pre
    height: 40rem
    padding: 1rem 1.25rem
    line-height: 2
    overflow: auto


.editor-button
    padding: 0.5rem
    border-radius: 1e10rem
    outline: none
    appearance: none
    cursor: pointer
    border: none
    background-color: unset

    svg
        fill: $editor-fg

    &:hover
        background-color: colorscheme.$background-secondary

        svg
            fill: #fff


.config-editor
    width: 100%
    border: 1px solid $editor-border-color
    box-shadow: 0 0 1rem 0 #00000044
    border-radius: 0.25rem
    overflow: hidden

    .text-editor-header
        padding: 1rem 1.25rem
        display: flex
        font-family: fonts.$ui
        background-color: $editor-bg
        border-bottom: 1px solid $editor-border-color

        .text-editor-filename
            font-weight: 600

    .text-editor-content
        height: 40rem

    .text-editor-footer
        display: flex
        padding: 1rem 1.25rem
        justify-content: space-between
        border-top: 1px solid $editor-border-color
        font-family: fonts.$ui
        font-size: 0.8rem
        font-weight: 600
        color: $editor-fg

    .ace_editor
        line-height: 20px
        font-family: fonts.$monospace
        color: $editor-fg
        background-color: $editor-bg


    .ace_gutter
        background-color: $editor-gutter-bg
        color: hsl(0, 0, 100%)

        .ace_gutter-cell
            color: $editor-gutter-fg

            &.ace_gutter-active-line
                background-color: $editor-gutter-active-bg
                color: $editor-gutter-active-fg
                font-weight: 700


    .ace_marker-layer .ace_active-line
            background: $editor-line-active-bg


    .ace_cursor
        color: $editor-cursor-fg

    .ace_sb-v,
    .ace_sb-h
        div
            background: $editor-scrollbar-bg
            border: none

    .ace_marker-layer .ace_selection
            background: $editor-selection-bg
