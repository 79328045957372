@use "../globals/colorscheme"
@use "../globals/fonts"
@import "../mixins/layout"

$bg-color: white
$dropdown-bg-color: darken(colorscheme.$background-primary, 5%)
$accent-color: colorscheme.$accent
$link-color: colorscheme.$background-primary

.header
  background-color: $dropdown-bg-color


.header-container
  display: flex
  justify-content: space-between


.header-section
  display: flex
  align-items: center
  gap: 2rem


.main-menu
  position: fixed
  top: 0
  right: 0
  bottom: 0
  display: none
  flex-direction: column
  width: 100%
  z-index: 1000

  border-radius: 0.25rem
  background-color: $dropdown-bg-color

  +sm
    position: static
    padding: 0
    display: block
    border: none
    background-color: unset
    width: unset


  &.main-menu-open
    display: flex


.main-menu-section-close
  width: 2rem
  height: 2rem

  position: absolute
  top: 1.75rem
  right: 1.75rem

  display: flex
  justify-content: center
  align-items: center

  fill: colorscheme.$text-primary
  cursor: pointer


.main-menu-link
  width: min-content
  white-space: nowrap
  color: colorscheme.$text-primary
  font-family: fonts.$body-text
  font-size: 1rem
  cursor: pointer

  +sm
    font-family: fonts.$body-text
    color: white

.main-menu-button
    color: red
    appearance: none
    border: none
    

.main-menu-section-title
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 1rem

  font-family: fonts.$title
  color: colorscheme.$accent
  text-transform: uppercase
  font-weight: 600

  +sm
    display: none


.main-menu-section-content
  display: flex
  flex-direction: column
  gap: 1rem
  margin-left: 0.5rem

  +sm
    flex-direction: row
    gap: 2rem


.main-menu-dropdown
  display: none
  flex-direction: column

  +sm
    position: fixed
    top: 0
    bottom: 0
    right: 0
    width: unset

    // border-left: 1px solid colorscheme.$divider
    background-color: $dropdown-bg-color
    box-shadow: 0 0 1rem 0 hsla(0, 0, 0, 20%)

  +sm
    // right: 4rem
    width: 38%
  
  +lg
    width: 30%

  +xl
    width: 20%

  .main-menu-section
    padding: 2rem 2rem 0 2rem


  .main-menu-section-title
    display: flex
    margin-bottom: 1rem


  .main-menu-section-content
    display: flex
    flex-direction: column
    gap: 1rem


  .main-menu-link
    color: colorscheme.$text-primary
    font-family: fonts.$body-text

    &:hover
      color: #fff

.main-menu-link
  appearance: none
  border: none
  background-color: transparent
  outline: none
  padding: 0


.main-menu-section-bottom
  display: flex
  justify-content: space-between
  align-items: center
  margin-top: auto
  border-top: 1px solid colorscheme.$divider
  padding: 1.5rem 2rem !important

  a, a:visited
    color: #fff

    &:hover
      color: darken(#fff, 20%)


.main-menu-profile-link
  display: flex
  flex-direction: row
  gap: 1rem
  align-items: center
  font-family: fonts.$body-text
  font-weight: 600


.main-menu-open
  .main-menu-dropdown
    height: 100%
    display: flex

    .main-menu-section
      padding: 2rem 2rem 0 2rem

  .main-menu-section
    padding: 2rem 2rem 0 2rem

    +sm
      padding: 0


.main-menu-toggle-button
  cursor: pointer

  svg
    fill: white
    width: 2rem
    height: 2rem


.header-logo
  width: 10rem
  aspect-ratio: 100 / 14

  display: flex
  align-items: center

  background-size: contain
  background-repeat: no-repeat

  font-family: fonts.$logo
  font-style: italic
  font-size: 2rem
  color: white
  text-transform: uppercase
  
  user-select: none


.header-navigation
  display: none

  +sm
    display: block
