@use "../globals/fonts"
@use "../globals/colorscheme"
@import "../mixins/layout.sass"

.explorer-header
  border-bottom: 1px solid colorscheme.$divider


.explorer-header-type-badges
  display: flex
  flex-wrap: wrap
  gap: 0.5rem


.explorer-header-title
  margin-bottom: 2rem

  font-family: fonts.$body-text
  font-size: 2rem
  color: #fff


.explorer-descriptor-list
  display: flex
  flex-direction: column
  row-gap: 2rem

  .config-descriptor
    background: darken(colorscheme.$background-primary, 5%)
    border-radius: 0.5rem
