@use "../globals/breakpoints"
@use "../globals/colorscheme"
@use "../globals/fonts"
@import "../mixins/layout"

$bg-color: darken(colorscheme.$background-primary, 5%)

.footer
  background-color: $bg-color
  font-family: fonts.$body-text
  color: white


.footer-container
    display: flex
    flex-direction: column
    gap: 2rem
    align-items: center

    +xs
      flex-direction: row
      gap: 2rem
      align-items: center


.footer-section-primary
  flex: 1

.footer-section-secondary
  display: flex
  flex-direction: column
  align-items: center


  +xs
    align-items: end


.footer-logo
  display: block
  font-size: 1.75rem
  text-align: center

  +xs
    display: flex


.footer-copyright-declaration
  margin-top: 0.3rem

  font-size: 0.9rem
  color: colorscheme.$text-primary


.footer-links
  display: flex
  flex-direction: column
  list-style-type: none
  gap: 1rem
  align-items: center
  
  +xs
    flex-direction: row





.footer-link
  p, a, a:visited
    font-weight: 500
    color: colorscheme.$text-primary

  p
    cursor: pointer


.contact-overlay-subtitle
  padding-top: 1.5rem

  font-size: 1.5rem
  font-weight: 600
  text-align: center

  &:not(:first-child)
    margin-top: 1.5rem

    border-top: 1px solid colorscheme.$divider


.contact-overlay-email
  width: min-content
  margin: 1rem auto
  padding: 0.5rem 1rem

  font-size: 1.33rem
  text-align: center
  border-radius: 0.5rem
  border: 1px solid colorscheme.$accent
  background-color: darken($bg-color, 5%)

  +sm
    font-size: 1.5rem

  +md
    font-size: 1.75rem


.contact-overlay-devteam
  margin-top: 2rem
  margin-bottom: 2rem
  display: flex
  flex-direction: column
  gap: 2rem

  +xs
    flex-direction: row
    justify-content: space-evenly


.development-team-member-card
  display: flex
  flex-direction: column
  align-items: center
  gap: 1rem


.development-team-member-avatar
  width: 10rem
  height: 10rem

  border-radius: 1e10rem
  border: 2px solid colorscheme.$accent


.development-team-member-full-name
  font-weight: 600
  color: colorscheme.$accent


.development-team-member-social-entry
  display: flex
  align-items: center
  gap: 0.75rem

  font-family: fonts.$monospace
  font-size: 1rem

  a, a:visited
    color: #fff

  a:hover
    text-decoration: underline
