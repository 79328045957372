@use "../globals/fonts"
@use "../globals/colorscheme"

.type-badge
  padding: 0.5rem 0.75rem

  font-family: fonts.$ui
  font-size: 0.7rem
  font-weight: 600
  color: #fff

  border-radius: 500rem
  border: 1px solid lighten(colorscheme.$background-secondary, 15%)
  background-color: colorscheme.$background-secondary

  appearance: none
  outline: none

  cursor: pointer

  transition: background-color 300ms, border-color 300ms

  &:not(.type-badge-selected):hover
    background-color: lighten(colorscheme.$background-secondary, 5%)


.type-badge-selected
  background-color: colorscheme.$accent
  border-color: transparent
