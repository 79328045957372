/* Source: normalize.css */
body
  margin: 0

// Source: normalize.css/Bootstrap
[hidden]
  display: none !important

/* Source: Bootstrap */
*,
*::before,
*::after
  box-sizing: border-box

/* Source: normalize.css */
button,
input,
optgroup,
select,
textarea,
/* Source: Tailwind CSS */
blockquote,
ol,
ul,
dl,
dd,
hr,
menu,
figure,
pre
  margin: 0

/* Source: Tailwind CSS */
hr
  height: 0

/* Source: normalize.css */
h1
  font-size: 2em
  margin-top: 0.67em
  margin-bottom: 0.67em

/* Source: Bootstrp */
h2,
h3,
h4,
h5,
h6
  margin-top: 0
  margin-bottom: 0
  font-size: inherit

/* Source: Bootstrp */
p
  margin-top: 0
  margin-bottom: 0

/* Source: Tailwind CSS */
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object
  display: block
  vertical-align: middle

/* Source: Tailwind CSS */
img,
video
  max-width: 100%
  height: auto

/* Source Bootstrap */
fieldset
  min-width: 0
  margin: 0
  padding: 0

/* Source: Tailwind CSS */
summary
  display: list-item

/* Source Bootstrap */
legend
  width: 100%
  padding: 0

/* Source: Tailwind CSS */
ol,
ul,
menu
  padding: 0

a
  text-decoration: none
