@use "util/normalize"
@use "globals/colorscheme"
@use "globals/breakpoints"
@use "globals/fonts"
@import "mixins/layout.sass"

@font-face
  font-family: Iceland
  src: url(../assets/fonts/Iceland-Regular.ttf)
  font-swap: swap


$bg-color: #D5D5D5
$body-bg-color: colorscheme.$background-primary
$textarea-bg-color: darken($bg-color, 15%)
$button-bg-color: darken($bg-color, 15%)
$navbar-bg-color: colorscheme.$background-primary
$primary-text-color: colorscheme.$text-primary
$accent-color: colorscheme.$accent

body
  background-color: colorscheme.$background-primary


#root
  min-height: 100vh
  display: flex
  flex-direction: column

.page
  color: #fff
  font-family: fonts.$body-text


.page__content
  flex-grow: 1


.display-ib
  display: inline-block


.display-flex
  display: flex

  &.align-end
    justify-content: end


.flex-fill
  flex: 1


.width-fill
  width: 100%


.color-accent
  color: $accent-color


.text-bold
  font-weight: 600


.form
  display: flex
  flex-direction: column
  gap: 1.5rem


.form__track
  display: flex
  align-items: center
  gap: 1.5rem


.form__track--vertical
  flex-direction: column


.form__track--gapless
  gap: 0


.form__item
  display: flex
  font-family: fonts.$ui
  gap: 0.5rem


.form__item--vertical
  flex-direction: column


.form__item--grow
  flex-grow: 1
  width: 100%


.form__track--responsive-sm
  flex-direction: column

  +sm
    flex-direction: row


.form__track--responsive-xs
  flex-direction: column

  +xs
    flex-direction: row


.form__label
  color: #fff


.form__field,
.form__select
  background-color: darken(colorscheme.$background-primary, 3%)
  border: 1px solid lighten(colorscheme.$background-primary, 6%)
  border-radius: 0.25rem
  outline: none
  padding: 0.5rem
  font-family: fonts.$ui
  color: colorscheme.$text-primary


.form__field
  transition: border-color 200ms, color 200ms

  &:focus
    border-color: colorscheme.$accent
    color: #fff
