@import "../globals/breakpoints"

=use-breakpoint($name)
  $size: get-breakpoint($name)
  @media only screen and (min-width: $size)
    @content

=xs
  +use-breakpoint(xs)
    @content

=sm
  +use-breakpoint(sm)
    @content

=md
  +use-breakpoint(md)
    @content

=lg
  +use-breakpoint(lg)
    @content

=xl
  +use-breakpoint(xl)
    @content

=xxl
  +use-breakpoint(xxl)
    @content
