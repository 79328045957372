@use "../globals/breakpoints"
@use "../globals/colorscheme"
@use "../globals/fonts"
@import "../mixins/layout"
  
$primary-text-color: colorscheme.$text-primary
$accent-color: colorscheme.$accent
$bg-color: colorscheme.$background-secondary

$max-content-width: map-get(breakpoints.$breakpoints, sm)
$body-text-font: fonts.$body-text
$title-font: Iceland

    
.new-config 
    max-width: $max-content-width
    margin: 0 auto
    padding-top: 3rem
    padding-bottom: 3rem
    font-family: $body-text-font
    color: #fff

    section
        margin-right: 3rem
        margin-left: 3rem

        padding-top: 3rem
        padding-bottom: 3rem

        &:first-child
          padding-top: 0

        &:last-child
          padding-bottom: 0


.config-creator-title
    margin-top: 0


.new-config-creation-box
    margin: 3rem
    padding: 4rem
    display: flex
    flex-direction: column
    background: $bg-color
    border-color: $accent-color
    border-style: solid
    border-radius: 5px
    border-width: 1px
    box-shadow: 0 0 1rem 0 rgb(0 0 0 / 0.2)


.new-config-options
    display: flex
    flex-direction: row
    justify-content: space-between
    align-text: center
    padding: 0.25rem

.checkbox
    display: flex
    flex-direction: column
    align-items: center
    font-family: fonts.$body-text
    font-size: 1.6rem
    color: $primary-text-color
    font-weight: 500


.checkbox-style
    display: flex
    width: 16px
    height: 16px


.new-config-dropdown
    display: flex
    padding-bottom: 2rem
    max-width: 40%
    flex-direction: column
    font-family: fonts.$ui
    font-size: 1.33rem
    font-weight: 400
    color: white


.form-row-config
    display: flex
    padding-bottom: 2rem
    flex-direction: column
    font-family: fonts.$body-text
    font-size: 1.33rem
    font-weight: 400
    color: white


.create-config-button
    padding: 0.7rem 2rem
    font-family: fonts.$ui
    font-size: 1.5rem
    font-weight: 600
    background-color: $accent-color
    color: white
    border-style: none
    border-radius: 5px


.config-creator-description
  width: 100%
  height: 8rem
  resize: none
