@use "../globals/breakpoints"
@use "../globals/colorscheme"
@use "../globals/fonts"
@use "../layout/section"
@use "../layout/container"
@import "../mixins/layout.sass"

$max-width: map-get(breakpoints.$breakpoints, md)
$body-bg-color: colorscheme.$background-secondary
$navbar-bg-color: colorscheme.$background-primary
$navbar-link-color: colorscheme.$text-primary
$accent-color: colorscheme.$accent

.registration-title
  margin-bottom: 2rem

  font-family: fonts.$title
  font-size: 1.5rem
  font-weight: 400
  text-align: center
  color: #fff


.registration-button
  padding: 0.7rem 2rem
  justify-content: center
  font-family: fonts.$ui
  font-size: 1rem
  font-weight: 600
  background-color: $accent-color
  color: #fff
  border-style: none
  border-radius: 0.25rem
  text-decoration: none
  outline: none
  cursor: pointer

  &:hover
    background-color: darken($accent-color, 3%)


.registration-form
  padding: 1.5rem
  border-radius: 0.25rem
  border: 1px solid lighten(colorscheme.$background-primary, 6%)
  background-color: darken(colorscheme.$background-primary, 1%)


.registration-form-row
  display: flex
  flex-direction: column
  gap: 2rem

  +sm
    flex-direction: row


.registration-form-column
  width: 100%
  

.sign-in-link, .sign-in-link:visited
  font-weight: 600
  text-align: end
  color: colorscheme.$accent


.registration-form-text
  margin-top: 0.75rem

  font-family: fonts.$body-text
  font-size: 0.9rem
  text-align: end
  color: #fff
