@use "sass:map"

$spacings: (huge: (top: 8rem, bottom: 8rem), large: (top: 6rem, bottom: 6rem), normal: (top: 4rem, bottom: 4rem), small: (top: 2rem, bottom: 2rem))

=spacing($size)
  $spacing: map.get($spacings, $size)

  padding-top: map.get($spacing, top)
  padding-bottom: map.get($spacing, bottom)

.section
  width: 100%

  +spacing(normal)


.section--small
  +spacing(small)


.section--large
  +spacing(large)


.section--huge
  +spacing(huge)


.jumbotron
  padding: 0
  display: flex
  align-items: center


.jumbotron--large
  min-height: 75vh
