@use "../globals/breakpoints"
@use "../globals/colorscheme"
@use "../globals/fonts"
@import "../mixins/layout.sass"

$title-bg: colorscheme.$accent
$title-fg: #fff
$title-font: fonts.$title
$title-font-size: 1.5rem
$border-color: colorscheme.$accent
$corner-radius: 0.25rem
$content-primary-bg: colorscheme.$background-primary
$content-secondary-bg: colorscheme.$background-secondary
$content-font: fonts.$body-text
$content-weight: 600
$accent-color: colorscheme.$accent
$primary-text-color: colorscheme.$text-primary

.config-box
  background-color: darken(colorscheme.$background-primary, 5%)
  color: #fff
  border-radius: 0.5rem
  overflow: hidden
  height: 100%
  display: flex
  flex-direction: column
  box-shadow: 0 0 1rem 0 #00000020
  transition-duration: 100ms
  font-family: fonts.$body-text

  &:hover
    transform: scale(1.01)

  ul
    list-style-type: none

  svg
    fill: #fff

.config-box-name,
.config-box-game
  display: inline-block


.config-box-name,
.config-box-name:visited
  color: #fff


.config-box-name
  padding: 0.5rem 0.75rem
  background-color: colorscheme.$accent
  border-bottom-right-radius: 0.5rem
  font-size: 1rem
  font-weight: 500


.config-box-game
  width: 100%
  padding: 0.5rem 0.75rem
  overflow: hidden

  white-space: nowrap
  text-overflow: ellipsis
  font-size: 0.7rem
  font-weight: 600
  background-color: darken(colorscheme.$background-primary, 10%)

  +xs
    width: unset
    margin-left: auto
    border-top-left-radius: 0.5rem


.config-box-main
  margin: 1.5rem
  flex-grow: 1


.config-box-description
  font-size: 1rem
  line-height: 1.5
  color: colorscheme.$text-primary

  &.italic
    font-style: italic
    color: lighten(colorscheme.$background-secondary, 10%)
  

.config-box-stats
  display: flex
  gap: 1.5rem
  justify-content: flex-start
  margin-left: 1.5rem
  flex-grow: 1

  div
    display: flex
    gap: 0.5rem


.config-box-edge
  display: flex


.config-box-link,
.config-box-link:visited
  color: #fff
