@use "../../globals/fonts"
@use "../../globals/colorscheme"

/**
 * Button
 */

$font-family: fonts.$ui

$regular-bg-color: colorscheme.$accent
$blank-bg-color: darken(colorscheme.$background-primary, 3%)
$blank-border-color: lighten(colorscheme.$background-primary, 6%)
$ok-bg-color: colorscheme.$ok
$danger-bg-color: colorscheme.$danger

=hovered($bg-color, $amount: 5%)
    &:hover
        background-color: darken($bg-color, $amount)

.button
    padding: 0.3rem 1rem
    appearance: none
    outline: none
    border: none
    border-radius: 0.33rem
    font-family: $font-family
    font-size: 0.875rem
    font-weight: 500
    cursor: pointer
    color: #fff
    transition: background-color 200ms


.button--ok
    background-color: $ok-bg-color
    +hovered($ok-bg-color)


.button--regular
    background-color: $regular-bg-color
    +hovered($regular-bg-color)


.button--blank
    box-sizing: content-box
    background-color: $blank-bg-color
    border: 1px solid $blank-border-color
    +hovered($blank-bg-color, 2%)


.button--danger
    background-color: $danger-bg-color
    +hovered($danger-bg-color, 10%)


.button--icon
    display: flex
    justify-content: center
    align-items: center
    gap: 0.5rem

    svg
        fill: #fff
        width: 0.75rem


.button--large
  padding: 0.5rem 1rem
  font-size: 1rem
  font-weight: 600

/**
 * Button Group
 */

$button-group-gap-h: 0.75rem

.button__group
    display: flex
    gap: $button-group-gap-h

.button__group--grow
    .button
        flex-grow: 1
